import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Werkwijze = () => {
  return (
    <Layout>
      <Seo title="Werkwijze" />
      <article>
        <h1>Werkwijze</h1>
        <p>
          De nadruk ligt op oplossingen en niet op het uitdiepen van de
          moeilijkheden. Uiteraard kijken we samen naar de moeilijkheden die je
          op dit moment ervaart. Tegelijkertijd richten we de aandacht op wat
          jij wilt in plaats van de moeilijkheden. Hoe zou jouw leven er uit
          zien zonder de problemen?
          <br />
          <br />
          Ik vind het belangrijk om erbij te vermelden dat ik als psychologe
          geen oplossingen op een blaadje kan aanbieden. De bedoeling is om te
          focussen op jouw krachten en doelen. Motivatie, toekomstgerichtheid,
          creativiteit en uitzonderingen op de regel vormen kernwoorden bij jouw
          proces. Deze krachtige factoren spelen een belangrijke rol bij een
          positieve verandermodus, waardoor je sneller het gewenste resultaat
          kan bereiken.
          <br />
          <br />
          Verschillende oplossingen kunnen bij eenzelfde probleem van toepassing
          zijn, afhankelijk van jouw unieke context en individuele waarden. ‘DE’
          oplossing bestaat dus niet. Wat gaat er goed? Wat werkt er wel en wat
          kan bijdragen tot een (deel)oplossing? Centraal staat het op zoek gaan
          naar haalbare doelen en hier in kleine stapjes aan werken.
        </p>
      </article>
      <article>
        <h1>Veilige omgeving, krachtbronnen en positiviteit</h1>
        <p>
          Door zelf te maken te krijgen met pieker- en negatieve gedachten kan
          ik me inleven in hoe jij je voelt op je minder gelukkige momenten.
          Samen met jou wil ik op weg naar oplossingen en een positievere
          toekomst. Dit kan alleen maar wanneer je ‘de klik voelt’ zoals er
          gezegd wordt. Dit probeer ik jou aan te bieden door middel van een
          veilige en vertrouwde omgeving. Tegelijkertijd zal dit gepaard gaan
          met een niet-oordelende houding langs mijn kant. <br />
          <br />
          De weg die we samen bewandelen, doen we op basis van het zoeken naar
          krachtbronnen. Wat zijn jouw sterktes en krachten? Waar ben jij goed
          in? Waar haal jij plezier en rust uit? Door te focussen op jouw
          krachtbronnen is er onmiddellijk sprake van een positieve invalshoek.
          Begrijp me niet verkeerd, er is zeker plaats voor een traan, maar
          zeker ook voor een lach.
        </p>
      </article>
    </Layout>
  );
};

export default Werkwijze;
